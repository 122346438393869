<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmareatype.title')">
					<v-layout wrap>
						<v-flex xs12 :md6="!isSuperAdmin" :md4="isSuperAdmin">
							<pui-text-field :label="$t('pmareatype.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 :md6="!isSuperAdmin" :md4="isSuperAdmin">
							<pui-text-field
								v-model="model.acronym"
								:label="$t('pmareatype.acronym')"
								maxlength="5"
								required
								toplabel
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								v-if="isSuperAdmin"
								attach="pmorganizationid"
								:label="this.$t('pmareatype.organization')"
								:placeholder="this.$t('pmareatype.phorganization')"
								toplabel
								clearable
								required
								reactive
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area v-model="model.description" :label="$t('pmareatype.description')" maxlength="250" toplabel></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmareatype.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { isSuperAdmin } from '@/api/common';

export default {
	name: 'pmareatypeform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmareatype',
			model: 'pmareatype',
			isSuperAdmin: false
		};
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	computed: {
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	updated() {
		if (!this.isSuperAdmin) {
			this.model.pmorganizationid = this.userProperties['organizationid'];
		}
	},
	methods: {}
};
</script>
